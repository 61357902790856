import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Import CSS
import '../styles/HomePage.css';

// Import Components
import Header from '../components/Header';
import HeadBanner from '../components/HeadBanner';
import Footer from '../components/Footer';
import EmailGetter from '../components/EmailGetter';
import ProductPresentation from '../components/ProductPresentation';
import Popup from '../components/InfoPopup';

function HomePage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('Cela peut être causé par un problème de notre côté.');
  const [title, setTitle] = useState('Oups, une erreur est survenue...');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const checkQuery = async (queryParams) => {
      const isTokenValid = async (verifEmail, verifToken) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/verify-vip-email-token`, { email: verifEmail, token: verifToken });
          const isTokenVerified = [200, 208].includes(response.status);
          setIsLoading(false)
          return isTokenVerified;
        } catch (error) {
          setIsLoading(false)
          return false;
        }

      };

      const email = queryParams.get('email');
      const token = queryParams.get('token');

      const isQueryParamsValid = (email && token && queryParams.size === 2);

      if (!isQueryParamsValid) {
        navigate('/Whitelist');
        return;
      }

      if (await isTokenValid(email, token)) {
        setIsSuccess(true);
        setTitle("Bienvenue chez Dums !");
        setText("Votre email à bien était enregistré pour le lancement privé")
      }
      setShowPopup(true);
    };

    if (queryParams.size !== 0) {
      setIsLoading(true)
      checkQuery(queryParams);
    }
  }, [location.search, navigate]);

  return (
    <div className="HomePage">
      <Header />
      <HeadBanner />
      <main>
        <ProductPresentation />
        <EmailGetter />
      </main>
      <Footer />
      {showPopup && (
        <Popup
          onClose={() => {
            setShowPopup(false);
            navigate('/Whitelist');
          }}
          text={text}
          success={isSuccess}
          title={title}
          loading={isLoading}
        />
      )}
    </div>
  );
}

export default HomePage;
