// src/components/Popup.js
import React from 'react';
import ErrorLogo from '../images/Error.svg'
import ConfirmationLogo from '../images/Confirmation.svg'
import '../styles/InfoPopup.css';

function Popup({ onClose, loading, text,success, title }) {
  return (
    <div className="info-popup">
      {!loading && (
        <div className="info-popup-content">
          <h2>{title}</h2>
          <p>{text}</p>
          {success ? (
            <img src={ConfirmationLogo} alt='Confirmation logo' />
          ) : (
            <img src={ErrorLogo} alt='Error logo' />
          )}
          <button onClick={onClose}>Continuer</button>
        </div>
      )}
    </div>
  );
}

export default Popup;
