import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/Whitelist" element={<HomePage />} />
        {/* Redirect to homepage for undefined routes, preserving query string */}
        <Route path="*" element={<Navigate to={`/Whitelist`} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
