import React, { useState } from 'react';
import '../styles/EmailGetter.css';
import parseHtml from 'html-react-parser';
import axios from 'axios';

function EmailGetter() {
  const [email, setEmail] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function isValidEmail(emailValue) {
    // Regular expression for validating an Email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test the email with the regex
    return regex.test(emailValue);
  }

  const handleSubmit = async (event) => {

    setIsLoading(true)

    event.preventDefault();
    
    if (!isValidEmail(email)) {
      setInfoMessage('Saisissez une adresse e-mail valide');
      setIsValid(false)
      return setIsLoading(false)
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/send-verification-vip-email`, { email });

      if(response.status !== 200) {
        setIsValid(false)
        setInfoMessage('Un problème est survenu, veuillez réessayer plus tard.')
      }
      
      setIsValid(true)
      setIsLoading(false)
      return setInfoMessage(response.data)

    } catch (error) {
      setIsValid(false)
      setIsLoading(false)
      setInfoMessage('Un problème est survenu, veuillez réessayer plus tard.')
    }
  };

  return (
    <div className="email-getter">
      <form onSubmit={handleSubmit}>
        <h2>
          Inscris-toi au lancement privé !
        </h2>
        <h3>
          Soit parmis les premiers à utiliser Dums en participant au lancement privé à partir de <strong>1€</strong> de mise
        </h3>
        <div className='email-div'>
          <input
            type="text"
            id='email'
            autoComplete='on'
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            formNoValidate
          />
          {isLoading ? <div class="loader"></div> : <p className={`info ${isValid === null ? '' : isValid ? 'valid' : 'invalid'}`}>
            {parseHtml(infoMessage)}
          </p>}
          
        </div>
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}

export default EmailGetter;
