import React from 'react';
import '../styles/HeadBanner.css';

function HeadBanner() {
  return (
    <div className="head-banner">
      <h1>Accès anticipé</h1>
    </div>
  );
}

export default HeadBanner;
