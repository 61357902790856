import React from 'react';
import mainLogo from '../images/MainLogo.svg'
import '../styles/Footer.css';

function Footer() {
  const links = [
    {name: "A propos de nous", href: "#root"},
    {name: "Nous contacter", href: "#root"},
    {name: "Conditions générales d'utilisation", href: "#root"},
    {name: "Mentions légales", href: "#root"},
  ]
  return (
    <footer className="footer">
      <img src={mainLogo} alt="Logo Principal de Dums" />
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.href}>{link.name}</a>
          </li>
        ))}
      </ul>
      <p>® Dums - Tous droits réservés - Mentions légales</p>
    </footer>
  );
}

export default Footer;
