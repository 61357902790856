import React from 'react';
import mainLogo from '../images/MainLogo.svg'
import '../styles/Header.css';

function Header() {
  return (
    <header className="header">
      <img src={mainLogo} alt="Logo principale de Dums" />
    </header>
  );
}

export default Header;
