import React from 'react';
import Iphone15_img from '../images/Iphone15.png'
import '../styles/ProductPresentation.css';

function ProductPresentation() {

  const product = {
    name: "Iphone 15",
    article_before_name:"l'"
  }

  return (
    <div className="product-presentation">
      <h2>U<span>n {product.name} pour 1</span>€</h2>
      <img src={Iphone15_img} alt={product.name} />
      <h3>Tu ne perdras pas vraiment car soit tu <strong>remportes</strong> {`${product.article_before_name}${product.name}`} soit tu te fais <strong>rembourser</strong> jusqu’à <strong>90%</strong> de ta somme miser.</h3>
    </div>
  );
}

export default ProductPresentation;
